import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/controladoria/precoDeVenda1.jpeg'
import banner2 from '../../../images/pages/controladoria/precoDeVenda2.jpeg'
import {
  faDollarSign,
  faReceipt,
  faPercentage,
  faMoneyBillWave,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <>TRANSFORME O SEU PROCESSO DE FORMAÇÃO DE PREÇO DE VENDA</>,
    description: (
      <>
        Tenha um processo de formação de preços assertivo, maximize o lucro,
        cubra custos e ganhe competitividade
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>FORMAÇÃO DO PREÇO DE VENDA COMO ESTRATÉGIA COMPETITIVA</>,
    description: (
      <>
        Não tenha mais dúvidas de como precificar, entenda quais fatores
        considerar para criar a estratégia ideal para o seu negócio
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `Diman Serviços Mecânicos`,
    text: `Implantação da rotina de gestão através da reunião mensal de análise
    de resultados. Realizadas mudanças na gestão através da classificação
    adequada de gastos, sistematização de relatórios e planejamento
    orçamentário.`,
  },
  {
    title: `TPC Law`,
    text: `Implantação da rotina de gestão para controles operacionais e
    tomada de decisões pautada em KPIs, possibilitando um crescimento
    seguro e sustentável.`,
  },
  {
    title: `Tecca Engenharia de Sistemas`,
    text: `Após o projeto foram fechados dois grandes negócios, resultado de uma
    maior assertividade na formação do preço de venda. Trabalho feito
    com foco nos custos diretos e créditos e débitos de impostos.`,
  },
]

const FormacaoDoPrecoDeCustos: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faDollarSign,
      title: `Precificação baseada em custos
      `,
      description: (
        <>
          O Markup é um método de precificação com base no custo. Por definição,
          Markup é um índice multiplicador aplicado sobre o custo de um produto
          / serviço para a formação do preço de venda. Neste caso é necessário
          levar em consideração os impostos, taxas, despesas e o percentual de
          lucro desejado.
        </>
      ),
    },
    {
      faIcon: faReceipt,
      title: `Regime de Tributação`,
      description: (
        <>
          Imprescindível considerar as taxas e impostos no processo de
          precificação. Devem ser considerados os principais impostos que
          incidem sobre as receitas de vendas de produtos e serviços, como ICMS,
          PIS, COFINS e IPI, cada um com suas respectivas alíquotas, de acordo
          com o estado e regime de tributação de cada negócio.
        </>
      ),
    },
    {
      faIcon: faPercentage,
      title: `Margem de Contribuição`,
      description: (
        <>
          A margem de contribuição representa o quanto o lucro da venda de cada
          produto contribuirá para a empresa cobrir todos os seus custos e
          despesas fixas e ainda gerar lucro. A margem de contribuição é parte
          integrante da precificação, se a margem não é conhecida, a empresa
          pode estar vendendo bastante e ainda assim estar no prejuízo.
        </>
      ),
    },
    {
      faIcon: faMoneyBillWave,
      title: `Tabelas de Comissões
      `,
      description: (
        <>
          Importante levar em consideração as comissões sobre vendas no momento
          da precificação. A comissão de vendas o percentual pago ao
          profissional de vendas por uma venda feita ou atingimento de uma meta.
          Para criar um bom modelo de comissionamento é preciso definir metas,
          taxas de comissões e datas de pagamento. O comissionamento é
          estratégico para a precificação.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Apontamento de custos',
      description: (
        <>
          Um bom processo de apuração de custos é fundamental para a
          precificação de produtos e serviços. Este processo se inicia
          garantindo a confiabilidade dos dados: apontamentos de custos
          (produção, estoque, ociosidade, ineficiência, perdas, devoluções,
          etc). Sem os apontamentos não é possível determinar os custos e
          consequentemente precificar de forma assertiva.
        </>
      ),
    },
    {
      count: 2,
      title: 'Apuração dos custos',
      description: (
        <>
          Após garantidos os apontamentos de custos, é necessário realizar a
          alocação ou apuração dos mesmos, classificando-os em custos diretos ou
          indiretos e ainda em fixos ou variáveis. Custos diretos estão ligados
          diretamente à produção ou aquisição de um produto ou prestação de um
          serviço, diferentemente do custo indireto. Já os custos variáveis são
          custos que se alteram conforme o volume de produção e vendas, ao
          contrário dos custos fixos, independentemente da empresa estar
          produzindo ou não.
        </>
      ),
    },
    {
      count: 3,
      title: 'Critério de Rateio',
      description: (
        <>
          O rateio de custos é um método para separar custos de acordo com os
          elementos que participam dele. Geralmente o rateio envolve processos
          de produção, centros de custos e projetos, dependendo da atividade de
          cada empresa. Existem alguns métodos tradicionais comumente
          utilizados: rateio por absorção, por headcount, por atividade e por
          faturamento. O rateio é imprescindível para embasar a tomada de
          decisão.
        </>
      ),
    },
    {
      count: 4,
      title: 'Formação do preço de venda',
      description: (
        <>
          Por fim, após estabelecimento de um processo assertivo de apuração de
          custos é possível tomar decisões para a formação do preço de venda de
          produtos e serviços, levando em consideração além dos custos, estudos
          de concorrência e demanda, definindo estratégias para ganhar
          competitividade diante do mercado.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Controladoria - Formaçāo do Preço de Venda - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Propor soluções através do uso dos métodos de precificação para melhorar o
              resultado do seu negócio, trabalhando com boas práticas de mercado."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
            descripitionSection={`Conheça agora os 04 principais passos de nossa metodologia para
            estruturação do processo de formação do preço de venda.`}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} isNumericCases={true} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default FormacaoDoPrecoDeCustos
